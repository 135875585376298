import React, { useState, useEffect } from "react";

function PlayerForfeitsTable(playerData) {
    const sessions = playerData.sessions.forfeit_sessions;

    return (
        <main>
          <table>
            <thead>
              <tr>
                <th style={{ backgroundColor: "green" }}>Session Id</th>
                <th style={{ backgroundColor: "green" }}>Credits Spent</th>
                <th style={{ backgroundColor: "green" }}>Playmode</th>
                <th style={{ backgroundColor: "green" }}>Rounds Won</th>
                {/* Add more headers as needed */}
              </tr>
            </thead>
            <tbody>
              {sessions.map((session) => (
                <tr key={session.session_id}>
                  <td>{session.session_id}</td>
                  <td>
                    {session.credits_spent === null ? "--" : session.credits_spent}
                  </td>
                  <td>
                    {session.play_mode === null ? "--" : session.play_mode}
                  </td>
                  <td>{session.rounds_won}</td>
                  {/* Add more columns as needed */}
                </tr>
              ))}
              <tr>
                <td>Total</td>
                <td>{(sessions.reduce((acc, cur) => acc + (cur.credits_spent || 0), 0)).toFixed(0)}</td>
              </tr>
            </tbody>
          </table>
        </main>
    );
}
export default PlayerForfeitsTable;