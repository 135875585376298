import React, { useState, useEffect } from "react";

function CreditHistory() {
  const [inputValue, setInputValue] = useState("");
  const [tableData, setTableData] = useState({
    history:[], 
    last_refund:"",
    forfeit_spend: 0
  });
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [lastRefund, setLastRefund] = useState("--");

  function getDate(x) {
    const myDate = new Date(x * 1000);
    return myDate.toLocaleString();
  }

  function sumRefunds(transactions) {
    console.log(transactions);
    return transactions.reduce((accumulator, current) => {
      if (current.purchase_type === "Refund") {
        return accumulator + parseInt(current.amount, 10);
      }
      console.log(accumulator);
      return accumulator;
    }, 0);
  }
  // Dummy function to simulate API call
  //   const fetchData = () => {
  //     setLoading(true);
  //     // Simulate fetching data
  //     setTimeout(() => {
  //       setTableData([
  //         { wins: 0, tickets: 100, extractors: 5, battlepoints: 200 },
  //         { wins: 1, tickets: 200, extractors: 10, battlepoints: 400 },
  //         { wins: 2, tickets: 300, extractors: 15, battlepoints: 500 },
  //       ]);
  //       setLoading(false);
  //     }, 2000); // Replace with actual API call
  //   };

  useEffect(() => {
    const fetchLastRefund = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/credits/lastrefund`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setLastRefund(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };
    fetchLastRefund();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    console.log("Fetching data");
    try {
      const response = await fetch(
        `https://api.ragequitchecker.com/credits?addr=${inputValue}`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      jsonData.history.sort((a, b) => b.block_num - a.block_num);
      setTableData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setTableData(null);
    } finally {
      setLoading(false);
      setDataLoaded(true);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    fetchData(); // Call the search function
  };

  const handleSubmit = () => {
    fetchData();
  };

  return (
    <main>
      <div>
        <b style={{ color: "black" }}>
          Check to see your list of credits transactions, including refunds. Updates every 15 minutes.
        </b>
        <br></br>
        <form onSubmit={handleFormSubmit}>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter your wallet address or ENS name"
        />
        <button type="Submit">Check Wallet</button>
        </form>
        {!loading && (
          <p style={{ color: "black", fontSize:"14px", fontStyle:"italic"}}>Last set of refunds sent by PV: <b>{lastRefund.last_refund}</b></p>
        )}
      </div>
      {loading && (
        <img src="/battlePlanText.webp" alt="Loading..." className="spinner" />
      )}
      {!loading && dataLoaded && (
        <div>
          <b style={{ color: "black" }}>
            Total refunded credits: {sumRefunds(tableData.history).toLocaleString()}
          </b><br></br>
          <b style={{ color: "black" }}>
          Total credits spent on forfeits: {tableData.forfeit_spend.toLocaleString()}
          </b>
        </div>
      )}
      {!loading && dataLoaded && (
        <table>
          <thead>
            <tr>
              <th>Wen</th>
              <th>Credits</th>
              <th title="Purchase Types: Refunds by PV, in game Tickets converted to credits, and POW/gg conversions">Purchase Type</th>
              <th>Block Number</th>
            </tr>
          </thead>
          <tbody>
            {tableData.history.map((row, index) => (
              <tr key={index}>
                <td>{getDate(row.timestamp)}</td>
                <td>{row.amount.toLocaleString()}</td>
                <td>{row.purchase_type}</td>
                <td>{row.block_num}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </main>
  );
}

export default CreditHistory;
