import React, { useState, useEffect } from "react";

function PlayerSessionsTable(playerData) {
    const sessions = playerData.sessions.recent_sessions;

    return (
        <main>
          <table>
            <thead>
              <tr>
                <th style={{ backgroundColor: "green" }}>Session Id</th>
                <th style={{ backgroundColor: "green" }}>Rating</th>
                <th style={{ backgroundColor: "green" }}>Playmode</th>
                <th style={{ backgroundColor: "green" }}>Rounds Won</th>
                <th style={{ backgroundColor: "green" }}>Ticket Payout</th>
                <th style={{ backgroundColor: "green" }}>Extractor Payout</th>
                <th style={{ backgroundColor: "green" }}>ROI</th>
                {/* Add more headers as needed */}
              </tr>
            </thead>
            <tbody>
              {sessions.map((session) => (
                <tr key={session.session_id}>
                  <td>{session.session_id}</td>
                  <td>
                    {session.wallet_info.rating === null ? "--" : session.wallet_info.rating}
                  </td>
                  <td>
                    {session.play_mode === null ? "--" : session.play_mode}
                  </td>
                  <td>{session.rounds_won}</td>
                  <td>{session.ticket_payout === null ? 0 : parseFloat(session.ticket_payout).toFixed(2)}</td>
                  <td>{session.extractor_payout === null ? 0 : parseFloat(session.extractor_payout).toFixed(2)}</td>
                  <td style={{ color: session.roi < 0 ? 'red' : 'black' }}>{session.roi === null ? 0 : (parseFloat(session.roi)*100).toFixed(2)}%</td>
                  {/* Add more columns as needed */}
                </tr>
              ))}
              <tr style={{fontStyle:"italic", fontWeight:"bolder", fontSize:"20px"}}>
                <td>Averages</td>
                <td></td>
                <td></td>
                <td>{(sessions.reduce((acc, cur) => acc + (cur.rounds_won || 0), 0)/sessions.length).toFixed(2)}</td>
                <td>{(sessions.reduce((acc, cur) => acc + (cur.ticket_payout || 0), 0)/sessions.length).toFixed(2)}</td>
                <td>{(sessions.reduce((acc, cur) => acc + (cur.extractor_payout || 0), 0)/sessions.length).toFixed(2)}</td>
                <td style={{ color: (sessions.reduce((acc, cur) => acc + (cur.roi || 0), 0)/sessions.length) < 0 ? 'red' : 'black' }}>{((sessions.reduce((acc, cur) => acc + (cur.roi || 0), 0)/sessions.length)*100).toFixed(2)}%</td>              
              </tr>
            </tbody>
          </table>
        </main>
    );
}
export default PlayerSessionsTable;