import React from "react";
import Header from "./Header"; // Make sure to create Header.js
import MainContent from './MainContent'; // Make sure to create MainContent.js
//import Footer from './Footer'; // Make sure to create Footer.js
import "./App.css"; // For your custom styles
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import CreditHistory from "./CreditHistory";
import Leaderboard from "./Leaderboard";
import RagequitChecker from "./Ragequit";
import StatsPage from "./Stats";
import PlayerProfile from "./PlayerProfile";
import Tips from "./Tip";
import ActiveSessions from "./ActiveSessions";
import RecentSessions from "./RecentSessions";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <nav>
          <ul>
            <li>
              <Link to="/" className="nav-link">Leaderboards</Link>
            </li>
            <li>
              <Link to="/activesessions" className="nav-link">Active Sessions</Link>
            </li>
            <li>
              <Link to="/recentsessions" className="nav-link">Recent Sessions</Link>
            </li>
            <li>
              <Link to="/credits" className="nav-link">Credits History</Link>
            </li>
            <li>
              <Link to="/supernova" className="nav-link">Ragequit Check</Link>
            </li>
            <li>
              <Link to="/stats" className="nav-link">Stats</Link>
            </li>
            <li>
              <Link to="/tips" className="nav-link">Tips</Link>
            </li>
          </ul>
        </nav>
        <Routes>
        <Route path="/supernova" element={<RagequitChecker />} />
          <Route path="/credits" element={<CreditHistory />} />
          <Route path="/stats" element={<StatsPage />} />
          <Route path="/" element={<Leaderboard />} />
          <Route path="/player" element={<PlayerProfile />} />
          <Route path="/tips" element={<Tips />} />
          <Route path="/activesessions" element={<ActiveSessions />} />
          <Route path="/recentsessions" element={<RecentSessions />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
