
import React, { useState, useEffect } from "react";

function PlayerRoiTable(playerData) {
    const payoutsData = playerData.payouts;

    return (
        <roi>
          {payoutsData.total_entry_fees > 0 && (
            <table>
                <colgroup>
                    <col style={{width: "400px"}}></col>
                </colgroup>
              <thead>
                <tr>
                  <th style={{width: "400px"}}></th>
                  <th style={{width: "400px"}}>
                    {" "}
                    <img
                      src="/credits.webp"
                      alt="Credits"
                      style={{ width: "60px" }}
                    />
                  </th>
                  <th style={{width: "400px"}}>
                    {" "}
                    <img
                      src="/tickets.webp"
                      alt="Tickets"
                      style={{ width: "60px" }}
                    />
                  </th>
                  <th style={{width: "400px"}}>
                    {" "}
                    <img
                      src="/extractors.webp"
                      alt="Extractors"
                      style={{ width: "60px" }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{width: "400px"}}>
                <td style={{backgroundColor: "#0b0707"}}>
                    <img
                      src="/cakewalk.webp"
                      alt="Cakewalk"
                      className="game-mode-image"
                    />
                  </td>
                  <td style={{ fontSize: "18px" }}>
                    {" "}
                    {payoutsData.payouts_per_mode.cakewalk.total_credits_spent.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}
                  </td>{" "}
                  {/* Replace with actual data */}
                  <td
                    style={{
                      color:
                        payoutsData.payouts_per_mode.cakewalk.total_credits_spent /
                          100 <
                        payoutsData.payouts_per_mode.cakewalk.total_ticket_payouts
                          ? "green"
                          : "red",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {payoutsData.payouts_per_mode.cakewalk.total_ticket_payouts.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}{" "}
                    ({payoutsData.payouts_per_mode.cakewalk.roi > 0 ? "+" : "-"}{payoutsData.payouts_per_mode.cakewalk.roi.toFixed(1)}%)
                  </td>{" "}
                  {/* Replace with actual data */}
                  <td style={{ fontSize: "18px" }}>
                    {" "}
                    {payoutsData.payouts_per_mode.cakewalk.total_extractor_payouts.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}
                  </td>{" "}
                  {/* Replace with actual data */}
                </tr>
                <tr>
                <td style={{backgroundColor: "#0b0707"}}>
                    <img
                      src="/pain.webp"
                      alt="Pain"
                      className="game-mode-image"
                    />
                  </td>
                  <td style={{ fontSize: "18px" }}>
                    {" "}
                    {payoutsData.payouts_per_mode.pain.total_credits_spent.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}
                  </td>{" "}
                  {/* Replace with actual data */}
                  <td
                    style={{
                      color:
                        payoutsData.payouts_per_mode.pain.total_credits_spent / 100 <
                        payoutsData.payouts_per_mode.pain.total_ticket_payouts
                          ? "green"
                          : "red",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {payoutsData.payouts_per_mode.pain.total_ticket_payouts.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}{" "}
                    ( {payoutsData.payouts_per_mode.pain.roi > 0 ? "+" : "-"}
                    {payoutsData.payouts_per_mode.pain.roi.toFixed(1)}%)
                  </td>{" "}
                  {/* Replace with actual data */}
                  <td style={{ fontSize: "18px" }}>
                    {" "}
                    {payoutsData.payouts_per_mode.pain.total_extractor_payouts.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}
                  </td>{" "}
                  {/* Replace with actual data */}
                </tr>
                <tr>
                  <td style={{backgroundColor: "#0b0707"}}>
                    <img
                      src="/carnage.webp"
                      alt="Carnage"
                      className="game-mode-image"
                    />
                  </td>
                  <td style={{ fontSize: "18px" }}>
                    {" "}
                    {payoutsData.payouts_per_mode.carnage.total_credits_spent.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}
                  </td>{" "}
                  {/* Replace with actual data */}
                  <td
                    style={{
                      color:
                        payoutsData.payouts_per_mode.carnage.total_credits_spent /
                          100 <
                        payoutsData.payouts_per_mode.carnage.total_ticket_payouts
                          ? "green"
                          : "red",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {payoutsData.payouts_per_mode.carnage.total_ticket_payouts.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}{" "}
                    ({" "}
                    {(payoutsData.payouts_per_mode.carnage.total_ticket_payouts /
                      (payoutsData.payouts_per_mode.carnage.total_credits_spent /
                        100) -
                      1) *
                      100 >
                    0
                      ? "+"
                      : "-"}
                    {payoutsData.payouts_per_mode.carnage.roi.toFixed(1)}%)
                  </td>{" "}
                  {/* Replace with actual data */}
                  <td style={{ fontSize: "18px" }}>
                    {" "}
                    {payoutsData.payouts_per_mode.carnage.total_extractor_payouts.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}
                  </td>{" "}
                  {/* Replace with actual data */}
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      backgroundColor: "#0b0707",
                      color:"white",
                      fontSize: "18px"
                    }}
                  >
                    Total
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {payoutsData.total_entry_fees.toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })}
                  </td>{" "}
                  {/* Totals of Credits Spent */}
                  <td
                    style={{
                      color:
                        payoutsData.total_entry_fees / 100 <
                        payoutsData.total_tickets_earned
                          ? "green"
                          : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {payoutsData.total_tickets_earned.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}{" "}
                    (
                    {(payoutsData.total_tickets_earned /
                      (payoutsData.total_entry_fees / 100) -
                      1) *
                      100 >
                    0
                      ? "+"
                      : "-"}
                    {(
                      (payoutsData.total_tickets_earned /
                        (payoutsData.total_entry_fees / 100) -
                        1) *
                      100
                    ).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    %)
                  </td>{" "}
                  {/* Totals of Ticket Payouts */}
                  <td
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {payoutsData.total_extractors_earned.toLocaleString(
                      undefined,
                      { maximumFractionDigits: 0 }
                    )}
                  </td>{" "}
                  {/* Totals of Extractor Payouts */}
                </tr>
              </tbody>
            </table>
          )}
        </roi>
    );
}
export default PlayerRoiTable;