import React, { useState } from "react";

function RagequitChecker() {
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setData(null); // Clear the data when input changes
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    fetchData(); // Call the search function
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.ragequitchecker.com?addr=${inputValue}`
      );
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <div>
        <h3>Ragequit Community Shares</h3>
        <br></br>
        <b style={{ color: "black" }}>
          Larger wallets will take some time (a minute or more). Be patient,
          don't refresh.
        </b>
        <br></br>
        <form onSubmit={handleFormSubmit}>
          <input type="text" value={inputValue} onChange={handleInputChange} />
          <button type="Submit">Check Wallet</button>
        </form>
        {loading && (
          <img
            src="/battlePlanText.webp"
            alt="Loading..."
            className="spinner"
          />
        )}

        {data && (
          <div>
            <h4 style={{ color: "black" }}>
              Your $gg from Community Pool: {data.total.toLocaleString()}
            </h4>
            {data.dust.length <= 300 ? (
              <table>
                <thead>
                  <tr>
                    <th style={{ backgroundColor: "rgb(159, 25, 29)" }}>
                      Dust Id
                    </th>
                    <th style={{ backgroundColor: "rgb(159, 25, 29)" }}>
                      Shares
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.dust.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.rewards}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <b style={{ color: "black" }}>
                💥 You have more than 300 dust. That table would be HUUUUGE so
                not showing it 💥
              </b>
            )}
          </div>
        )}
      </div>
    </main>
  );
}

export default RagequitChecker;
