import React, { useState, useEffect } from "react";

function RecentSessions() {
  const [sessions, setSessions] = useState([]);
  const [averageRoi, setAverageRoi] = useState(0);
  const [averageRoundsWon, setAverageRoundsWon] = useState(0);
  const [averageTicketsWon, setAverageTicketsWon] = useState(0);
  const [averageExtractorsWon, setAverageExtractorsWon] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(60); // Refresh every 60 seconds
  const [autoRefresh, setAutoRefresh] = useState(false);

  // Function to fetch active sessions
  const fetchSessions = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://api.ragequitchecker.com/recentsessions"
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      const sortedData = data.sort((a, b) => b.session_id - a.session_id);
      
      setSessions(sortedData);

      console.log(sessions);
      setError(null); // Reset error state on successful fetch
    } catch (err) {
      setError(err.message); // Set error state on failure
    } finally {
      setLoading(false);
      if (sessions.length > 0) {
        const totalRoi = sessions.reduce((acc, cur) => acc + (cur.roi || 0), 0);
        const totalRoundsWon = sessions.reduce((acc, cur) => acc + (cur.rounds_won || 0), 0);
        const totalTicketsWon = sessions.reduce((acc, cur) => acc + (cur.ticket_payout || 0), 0);
        const totalExtractorsWon = sessions.reduce((acc, cur) => acc + (cur.extractor_payout || 0), 0);
        console.log("totalRoi: ", totalRoi);
        console.log("totalSessions: ", sessions.length);
        setAverageRoi((totalRoi / sessions.length)*100);
        setAverageRoundsWon(totalRoundsWon / sessions.length);
        setAverageTicketsWon(totalTicketsWon / sessions.length);
        setAverageExtractorsWon(totalExtractorsWon / sessions.length);
      } 
    }
  };

  // useEffect to run on component mount and set an interval for refreshing data
  useEffect(() => {
      fetchSessions();
  }, []);


  // Render component
  return (
    <main>
      {loading && (
        <img src="/battlePlanText.webp" alt="Loading..." className="spinner" />
      )}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
          <table>
            <thead>
              <tr>
                <th style={{ backgroundColor: "green" }}>Session Id</th>
                <th style={{ backgroundColor: "green" }}>Wallet</th>
                <th style={{ backgroundColor: "green" }}>Rating</th>
                <th style={{ backgroundColor: "green" }}>Playmode</th>
                <th style={{ backgroundColor: "green" }}>Rounds Won</th>
                <th style={{ backgroundColor: "green" }}>Ticket Payout</th>
                <th style={{ backgroundColor: "green" }}>Extractor Payout</th>
                <th style={{ backgroundColor: "green" }}>ROI</th>
                {/* Add more headers as needed */}
              </tr>
            </thead>
            <tbody>
              {sessions.map((session) => (
                <tr key={session.session_id}>
                  <td>{session.session_id}</td>
                  <td>
                    {session.wallet_info.ens === null
                      ? session.wallet_info.address
                      : session.wallet_info.ens}
                  </td>
                  <td>
                    {session.wallet_info.rating === null ? "--" : session.wallet_info.rating}
                  </td>
                  <td>
                    {session.play_mode === null ? "--" : session.play_mode}
                  </td>
                  <td>{session.rounds_won}</td>
                  <td>{session.ticket_payout === null ? 0 : parseFloat(session.ticket_payout).toFixed(2)}</td>
                  <td>{session.extractor_payout === null ? 0 : parseFloat(session.extractor_payout).toFixed(2)}</td>
                  <td style={{ color: session.roi < 0 ? 'red' : 'black' }}>{session.roi === null ? 0 : (parseFloat(session.roi)*100).toFixed(2)}%</td>
                  {/* Add more columns as needed */}
                </tr>
              ))}
              <tr>
                <td>Averages</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{(sessions.reduce((acc, cur) => acc + (cur.rounds_won || 0), 0)/sessions.length).toFixed(2)}</td>
                <td>{(sessions.reduce((acc, cur) => acc + (cur.ticket_payout || 0), 0)/sessions.length).toFixed(2)}</td>
                <td>{(sessions.reduce((acc, cur) => acc + (cur.extractor_payout || 0), 0)/sessions.length).toFixed(2)}</td>
                <td style={{ color: (sessions.reduce((acc, cur) => acc + (cur.roi || 0), 0)/sessions.length) < 0 ? 'red' : 'black' }}>{((sessions.reduce((acc, cur) => acc + (cur.roi || 0), 0)/sessions.length)*100).toFixed(2)}%</td>              
              </tr>
            </tbody>
          </table>
      )}
    </main>
  );
}

export default RecentSessions;
