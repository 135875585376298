import React from 'react';

function Tips() {
    const textToCopy = "0x547B6706e8708522980E9E17390A94cAfEFDDF09";
    const copyTextStyle = {
        cursor: 'pointer',
        fontSize: '20px'
      };

    const iconStyle = {
        cursor: 'pointer',
        marginLeft: '5px',
      };
      

    const handleCopyClick = () => {
      navigator.clipboard.writeText(textToCopy);
      //alert("Wallet address copied to clipboard!"); // Optional: give feedback to the user
    };

    return (
        <main>

            <h4 style={{color:"black"}}>Tricked ya didn't I? Sorry, no game tips here, but if you like this site feel free to send $GG or $ETH my way
                 so I can continue to run and build it!
            </h4>
            <img src="/pv_wallet.png" alt="0x547B6706e8708522980E9E17390A94cAfEFDDF09" style={{width:"180px"}}/>
            <p style={{color:"black"}}>Wallet Address: <span onClick={handleCopyClick} style={copyTextStyle}><b>{textToCopy} </b><span onClick={handleCopyClick} style={iconStyle}>📋</span></span></p>
        </main>
    );
}

export default Tips;
