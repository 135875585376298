import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

function StatsPage() {
  const [dailyData, setDailyData] = useState([]);
  const [playmodeData, setPlaymodeData] = useState({
    play_mode_counts: { carnage: 0, pain: 0, cakewalk: 0 },
    max_win_counts: { carnage: 0, pain: 0, cakewalk: 0 },
    avg_wins: { carnage: 0, pain: 0, cakewalk: 0 },
    avg_wins_daily: [],
    max_wins_daily: [],
  });
  const [forfeitData, setForfeitData] = useState([]);
  const [payoutsData, setPayoutsData] = useState({});
  const [retentionData, setRetentionData] = useState({});
  const [dailyRoiData, setDailyRoiData] = useState([]);
  const [dailyRoiByRatingData, setDailyRoiByRatingData] = useState([]);
  const [dailyCreditPurchaseData, setDailyCreditPurchaseData] = useState([]);
  const [creditPurchaseData, setCreditPurchaseData] = useState([]);
  const [ggClaimsData, setGgClaimsData] = useState([]);
  const [avgGamesData, setAvgGamesData] = useState([]);
  const [ratingsCountsData, setRatingsCountsData] = useState([]);
  const [avgVolatilityData, setAvgVolatilityData] = useState([]);
  const [avgGlickoData, setAvgGlickoData] = useState([]);
  const [avgWinsByRatingData, setAvgWinsByRatingData] = useState([]);
  const [maxWinsByRatingData, setMaxWinsByRatingData] = useState([]);
  const [avgFirstWinsData, setAvgFirstWinsData] = useState([]);
  const [walletGamesCounts, setWalletGamesCounts] = useState({
    olds: 0,
    newbs: 0,
  });
  const [activeTab, setActiveTab] = useState("game-stats");

  useEffect(() => {
    const fetchDailyData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setDailyData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchAvgFirstWinsData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/avgFirstWins`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setAvgFirstWinsData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchRetentionData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/retention`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setRetentionData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchAvgGamesData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/avggames`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setAvgGamesData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchRatingsCounts = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/ratingsCounts`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setRatingsCountsData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchAvgWinsByRatingData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/avgWinsByRating`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setAvgWinsByRatingData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchMaxWinsByRatingData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/maxWinsByRating`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setMaxWinsByRatingData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchAvgVolatilityData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/volatility`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setAvgVolatilityData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchAvgGlickoData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/glicko`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setAvgGlickoData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchGgClaimsDataData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/ggclaims`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setGgClaimsData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchWalletGameCounts = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/walletgamecounts`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setWalletGamesCounts(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchDailyRoiData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/roi`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setDailyRoiData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchDailyRoiByRatingData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/roiByRating`
        ); // Replace with your API endpoint
        const jsonData = await response.json();
        console.log(jsonData.data);
        setDailyRoiByRatingData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    const fetchPlaymodeData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/playmode`
        );
        const jsonData = await response.json();
        console.log(jsonData);
        setPlaymodeData(jsonData);
      } catch (error) {
        console.error("Error fetching additional data:", error);
        // Handle error appropriately
      }
    };

    const fetchForfeitData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/forfeits`
        );
        const jsonData = await response.json();
        setForfeitData(jsonData);
        console.log(forfeitData);
      } catch (error) {
        console.error("Error fetching additional data:", error);
        // Handle error appropriately
      }
    };

    const fetchCreditPurchaseData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/daily/creditpurchases`
        );
        const jsonData = await response.json();
        setDailyCreditPurchaseData(jsonData);
        //console.log(forfeitData);
      } catch (error) {
        console.error("Error fetching additional data:", error);
        // Handle error appropriately
      }

      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/creditpurchases`
        );
        const jsonData = await response.json();
        setCreditPurchaseData(jsonData);
        //console.log(forfeitData);
      } catch (error) {
        console.error("Error fetching additional data:", error);
        // Handle error appropriately
      }
    };

    const fetchPayoutsData = async () => {
      try {
        const response = await fetch(
          `https://api.ragequitchecker.com/stats/payouts`
        );
        const jsonData = await response.json();
        setPayoutsData(jsonData);
        console.log(forfeitData);
      } catch (error) {
        console.error("Error fetching additional data:", error);
        // Handle error appropriately
      }
    };

    fetchForfeitData();
    fetchDailyData();
    fetchPlaymodeData();
    fetchPayoutsData();
    fetchDailyRoiData();
    fetchCreditPurchaseData();
    fetchAvgGamesData();
    fetchWalletGameCounts();
    fetchGgClaimsDataData();
    fetchDailyRoiByRatingData();
    fetchAvgVolatilityData();
    fetchAvgGlickoData();
    fetchAvgWinsByRatingData();
    fetchRatingsCounts();
    fetchRetentionData();
    fetchAvgFirstWinsData();
    fetchMaxWinsByRatingData();
  }, []);
  // Data for Unique Player Count Chart

  function calculateTrendLine(yValues) {
    const xValues = yValues.map((_, index) => index); // Simple x values [0, 1, 2, 3, ...]
    const n = xValues.length;
    const sumX = xValues.reduce((acc, curr) => acc + curr, 0);
    const sumY = yValues.reduce((acc, curr) => acc + curr, 0);
    const sumXY = xValues.reduce((acc, curr, i) => acc + curr * yValues[i], 0);
    const sumXX = xValues.reduce((acc, curr) => acc + curr * curr, 0);
    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    // Generate the trend line points
    return xValues.map((x) => slope * x + intercept);
  }

  const today = new Date().toISOString().split("T")[0]; // Adjust format as needed
  const dataExceptToday = dailyData.filter(
    (item) => item.played_date !== today
  );
  const dataForToday = dailyData.filter((item) => item.played_date === today);

  const lastIndex = dailyData.length - 1;
  const secondLastIndex = lastIndex - 1;

  const medianGames = avgGamesData
    .slice(0, -1)
    .map((item) => item.median_games); // Assuming this is your yValues
  const medianGamesTrendLineData = calculateTrendLine(medianGames);

  const dailySessions = dataExceptToday.map((item) => item.total_session_count); // Assuming this is your yValues
  const dailySessionsTrendLineData = calculateTrendLine(dailySessions);
  // Create a dataset for the last two points, if there are at least two points
  const lastTwoPointsDailyPlayersDataset =
    lastIndex > 0
      ? [
          {
            //label: "Dotted Line",
            data: new Array(secondLastIndex)
              .fill(null) // Fill with nulls to align with the previous points
              .concat([
                dailyData[secondLastIndex].unique_player_count, // Second last point
                dailyData[lastIndex].unique_player_count, // Last point
              ]),
            borderColor: "grey", // Or any color you prefer
            backgroundColor: "rgba(0,0,0,0)", // Transparent
            borderWidth: 2,
            borderDash: [2, 2], // Creates a dotted effect, adjust numbers for different patterns
            fill: false, // Ensure the area under the line isn't filled
            tension: 0.1,
            pointRadius: 0, // Hide points for this dataset to emphasize only the line
          },
        ]
      : [];

  const lastTwoPointsDailySessionsDataset =
    lastIndex > 0
      ? [
          {
            //label: null,
            data: new Array(secondLastIndex)
              .fill(null) // Fill with nulls to align with the previous points
              .concat([
                dailyData[secondLastIndex].total_session_count, // Second last point
                dailyData[lastIndex].total_session_count, // Last point
              ]),
            borderColor: "grey", // Or any color you prefer
            backgroundColor: "rgba(0,0,0,0)", // Transparent
            borderWidth: 2,
            borderDash: [2, 2], // Creates a dotted effect, adjust numbers for different patterns
            fill: false, // Ensure the area under the line isn't filled
            tension: 0.1,
            pointRadius: 0, // Hide points for this dataset to emphasize only the line
          },
        ]
      : [];

  const lastTwoPointsNewPlayersDataset =
    lastIndex > 0
      ? [
          {
            label: "Dotted Line",
            data: new Array(secondLastIndex)
              .fill(null) // Fill with nulls to align with the previous points
              .concat([
                dailyData[secondLastIndex].new_player_count, // Second last point
                dailyData[lastIndex].new_player_count, // Last point
              ]),
            borderColor: "grey", // Or any color you prefer
            backgroundColor: "rgba(0,0,0,0)", // Transparent
            borderWidth: 1,
            borderDash: [2, 2], // Creates a dotted effect, adjust numbers for different patterns
            fill: false, // Ensure the area under the line isn't filled
            tension: 0.1,
            pointRadius: 0, // Hide points for this dataset to emphasize only the line
          },
        ]
      : [];

  const creditPurchaseChartData = {
    labels: dailyCreditPurchaseData.map((item) => item.played_date),
    datasets: [
      {
        label: "credits purchased in $gg terms",
        data: dailyCreditPurchaseData.map((item) => item.purchases / 100),
        borderColor: "green", // Bright red
        backgroundColor: "green", // Light green with transparency
        tension: 0.3,
      },
      {
        label: "$gg extracted",
        data: dailyCreditPurchaseData.map((item) => item.extractions),
        borderColor: "red", // Bright red
        backgroundColor: "red", // Light green with transparency
        tension: 0.3,
      },
      {
        label: "$gg in - $gg extracted",
        data: dailyCreditPurchaseData.map((item) => (item.purchases/100) - item.extractions),
        borderColor: "black", // Bright red
        backgroundColor: "black", // Light green with transparency
        tension: 0.3,
      },
    ],
  };

  const ggClaimsChartData = {
    labels: ggClaimsData.map((item) => item.played_date),
    datasets: [
      {
        label: "$gg claimed",
        data: ggClaimsData.map((item) => item.claimed),
        borderColor: "green", // Bright red
        backgroundColor: "green", // Light green with transparency
        tension: 0.3,
      },
    ],
  };

  const playerCountData = {
    labels: dailyData.map((item) => item.played_date),
    datasets: [
      {
        label: "Players",
        data: dataExceptToday.map((item) => item.unique_player_count),
        borderColor: "blue", // Bright red
        backgroundColor: "rgba(54, 162, 235, 0.5)", // Light green with transparency
        tension: 0.1,
      },
      {
        label: "Players Today (so far)",
        data: dailyData.map((item) =>
          item.played_date === today
            ? dataForToday[0]?.unique_player_count
            : null
        ),
        borderColor: "black",
        backgroundColor: "black",
        tension: 0.1,
      },
      ...lastTwoPointsDailyPlayersDataset,
    ],
  };

  const avgGamesChartData = {
    labels: avgGamesData.slice(0, -1).map((item) => item.played_date),
    datasets: [
      {
        label: "Average Games per Wallet",
        data: avgGamesData.slice(0, -1).map((item) => item.avg_games),
        borderColor: "blue", // Bright red
        backgroundColor: "rgba(54, 162, 235, 0.5)", // Light green with transparency
        tension: 0.2,
      },
      {
        label: "Median Games per Wallet",
        data: avgGamesData.slice(0, -1).map((item) => item.median_games),
        borderColor: "black",
        backgroundColor: "black",
        tension: 0.2,
      },
    ],
  };

  const avgFirstWinsChartData = {
    labels: avgFirstWinsData.map((item) => item.played_date),
    datasets: [
      {
        label: "First Game",
        data: avgFirstWinsData.map((item) => item.game_1),
        borderColor: "red", 
        backgroundColor: "red", // Light green with transparency
        tension: 0.2,
      },
      {
        label: "Second Game",
        data: avgFirstWinsData.map((item) => item.game_2),
        borderColor: "green",
        backgroundColor: "green",
        tension: 0.2,
      },
      {
        label: "Third Game",
        data: avgFirstWinsData.map((item) => item.game_3),
        borderColor: "blue",
        backgroundColor: "blue",
        tension: 0.2,
      },
    ],
  };

  const avgVolatilityChartData = {
    labels: avgVolatilityData.slice(0, -1).map((item) => item.played_date),
    datasets: [
      {
        label: "Average volatility",
        data: avgVolatilityData.slice(0, -1).map((item) => item.volatility),
        borderColor: "black", // Bright red
        backgroundColor: "rgba(54, 162, 235, 0.5)", // Light green with transparency
        tension: 0.2,
      },
    ],
  };

  const avgGlickoChartData = {
    labels: avgGlickoData.slice(0, -1).map((item) => item.played_date),
    datasets: [
      {
        label: "Average glicko",
        data: avgGlickoData.slice(0, -1).map((item) => item.glicko),
        borderColor: "black", // Bright red
        backgroundColor: "rgba(54, 162, 235, 0.5)", // Light green with transparency
        tension: 0.2,
      },
    ],
  };

  // avgGamesChartData.datasets.push({
  //   label: "Median Games Trend Line",
  //   data: medianGamesTrendLineData,
  //   borderColor: "grey",
  //   backgroundColor: "rgba(0,0,0,0)", // Make sure the background is transparent
  //   borderDash: [5, 5],
  //   pointRadius: 0, // This removes the dot data points from the trend line
  //   fill: false, // Optional: makes the trend line dashed
  // });

  const newPlayerCountData = {
    labels: dailyData.map((item) => item.played_date),
    datasets: [
      {
        label: "Players",
        data: dataExceptToday.map((item) => item.new_player_count),
        borderColor: "blue", // Bright red
        backgroundColor: "rgba(54, 162, 235, 0.5)", // Light green with transparency
        tension: 0.1,
      },
      {
        label: "New Players Today (so far)",
        data: dailyData.map((item) =>
          item.played_date === today ? dataForToday[0]?.new_player_count : null
        ),
        borderColor: "black",
        backgroundColor: "black",
        tension: 0.1,
      },
      ...lastTwoPointsNewPlayersDataset,
    ],
  };

  const combinedChartData = {
    labels: dailyData.map((item) => item.played_date), // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "Daily Sessions",
        data: dataExceptToday.map((item) => item.total_session_count),
        borderColor: "green", // Color for the Daily Sessions line
        backgroundColor: "rgba(144, 238, 144, 0.5)", // Light green with transparency
        tension: 0.1, // Line smoothness
        yAxis: "y",
      },
      {
        label: "Forfeits",
        data: forfeitData.map((item) => item.forfeits),
        borderColor: "red", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(255, 99, 132, 0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
        yAxis: "y1",
      },
      {
        label: "Sessions Today (so far)",
        data: dailyData.map((item) =>
          item.played_date === today
            ? dataForToday[0]?.total_session_count
            : null
        ),
        borderColor: "black",
        backgroundColor: "black",
        tension: 0.1,
      },
      ...lastTwoPointsDailySessionsDataset,
    ],
  };

  // combinedChartData.datasets.push({
  //   label: "Daily Sessions Trend Line",
  //   data: dailySessionsTrendLineData,
  //   borderColor: "grey",
  //   backgroundColor: "rgba(0,0,0,0)", // Make sure the background is transparent
  //   borderDash: [5, 5],
  //   pointRadius: 0, // This removes the dot data points from the trend line
  //   fill: false, // Optional: makes the trend line dashed
  // });

  const dailyRoiChartData = {
    labels: dailyRoiData.map((item) => item.played_date), // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "Cakewalk",
        data: dailyRoiData.map((item) => item.cakewalk),
        borderColor: "rgba(125,200, 207, 1)", // Color for the Daily Sessions line
        backgroundColor: "rgba(125,200, 207, 0.5)", // Light green with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Pain",
        data: dailyRoiData.map((item) => item.pain),
        borderColor: "rgba(234,160,57,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(234,160,57,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
    ],
  };

  const dailyRoiByRatingChartData = {
    labels: dailyRoiByRatingData.map((item) => item.played_date), // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "Casual",
        data: dailyRoiByRatingData.map((item) => item.casual),
        borderColor: "rgba(255,186,108, 1)", // Color for the Daily Sessions line
        backgroundColor: "rgba(255,186,108, 0.5)", // Light green with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Bruiser",
        data: dailyRoiByRatingData.map((item) => item.bruiser),
        borderColor: "rgba(131,142,143,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(131,142,143,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Ace",
        data: dailyRoiByRatingData.map((item) => item.ace),
        borderColor: "rgba(249, 223, 105,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(249, 223, 105,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Titan",
        data: dailyRoiByRatingData.map((item) => item.titan),
        borderColor: "rgba(34,133,139, 1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(34,133,139, 0.5", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
    ],
  };

  const dailyRatingsCountChartData = {
    labels: ratingsCountsData.map((item) => item.played_date), // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "Casual",
        data: ratingsCountsData.map((item) => item.casual),
        borderColor: "rgba(255,186,108, 1)", // Color for the Daily Sessions line
        backgroundColor: "rgba(255,186,108, 0.5)", // Light green with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Bruiser",
        data: ratingsCountsData.map((item) => item.bruiser),
        borderColor: "rgba(131,142,143,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(131,142,143,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Ace",
        data: ratingsCountsData.map((item) => item.ace),
        borderColor: "rgba(249, 223, 105,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(249, 223, 105,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Titan",
        data: ratingsCountsData.map((item) => item.titan),
        borderColor: "rgba(34,133,139, 1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(34,133,139, 0.5", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
    ],
  };

  const dailyWinsByRatingChartData = {
    labels: avgWinsByRatingData.map((item) => item.played_date), // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "Casual",
        data: avgWinsByRatingData.map((item) => item.casual),
        borderColor: "rgba(255,186,108, 1)", // Color for the Daily Sessions line
        backgroundColor: "rgba(255,186,108, 0.5)", // Light green with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Bruiser",
        data: avgWinsByRatingData.map((item) => item.bruiser),
        borderColor: "rgba(131,142,143,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(131,142,143,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Ace",
        data: avgWinsByRatingData.map((item) => item.ace),
        borderColor: "rgba(249, 223, 105,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(249, 223, 105,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Titan",
        data: avgWinsByRatingData.map((item) => item.titan),
        borderColor: "rgba(34,133,139, 1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(34,133,139, 0.5", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
    ],
  };

  const dailyMaxWinsByRatingChartData = {
    labels: maxWinsByRatingData.map((item) => item.played_date), // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "Casual",
        data: maxWinsByRatingData.map((item) => item.casual *100),
        borderColor: "rgba(255,186,108, 1)", // Color for the Daily Sessions line
        backgroundColor: "rgba(255,186,108, 0.5)", // Light green with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Bruiser",
        data: maxWinsByRatingData.map((item) => item.bruiser *100),
        borderColor: "rgba(131,142,143,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(131,142,143,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Ace",
        data: maxWinsByRatingData.map((item) => item.ace *100),
        borderColor: "rgba(249, 223, 105,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(249, 223, 105,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Titan",
        data: maxWinsByRatingData.map((item) => item.titan *100),
        borderColor: "rgba(34,133,139, 1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(34,133,139, 0.5", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
    ],
  };

  const combinedAvgWinsData = {
    labels: playmodeData.avg_wins_daily.map((item) => item.played_date), // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "Cakewalk",
        data: playmodeData.avg_wins_daily.map((item) => item.cakewalk),
        borderColor: "rgba(125,200, 207, 1)", // Color for the Daily Sessions line
        backgroundColor: "rgba(125,200, 207, 0.5)", // Light green with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Pain",
        data: playmodeData.avg_wins_daily.map((item) => item.pain),
        borderColor: "rgba(234,160,57,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(234,160,57,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
    ],
  };

  const combinedMaxWinsData = {
    labels: playmodeData.max_wins_daily.map((item) => item.played_date), // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "Cakewalk",
        data: playmodeData.max_wins_daily.map((item) => item.cakewalk * 100),
        borderColor: "rgba(125,200, 207, 1)", // Color for the Daily Sessions line
        backgroundColor: "rgba(125,200, 207, 0.5)", // Light green with transparency
        tension: 0.1, // Line smoothness
      },
      {
        label: "Pain",
        data: playmodeData.max_wins_daily.map((item) => item.pain * 100),
        borderColor: "rgba(234,160,57,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(234,160,57,0.5)", // Light red with transparency
        tension: 0.1, // Line smoothness
      },
    ],
  };


  const retentionChartData = {
    labels: ['Minimum Games Played'], // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "15-24 games",
        data: [retentionData["15-24 games"]],
        backgroundColor: getCategoryColor('15-24 games')
      },
      {
        label: "25-49 games",
        data: [retentionData['25-49 games']],
        backgroundColor: getCategoryColor('25-49 games')
      },
      {
        label: "50-99 games",
        data: [retentionData['50-99 games']],
        backgroundColor: getCategoryColor('50-99 games')
      },
      {
        label: "100-199 games",
        data: [retentionData['100-199 games']],
        backgroundColor: getCategoryColor('100-199 games')
      },
      {
        label: "200+ games",
        data: [retentionData['200+ games']],
        backgroundColor: getCategoryColor('200+ games')
      }
    ]
  };

const retentionChartOptions = {
  scales: {
  }
};

function getCategoryColor(category) {
  let color = '';
  if (category === '15-24 games'){
    console.log("Category is green colored");
    color = "#00286d"
  }
  else if (category === '25-49 games'){
    color = "#ffce00"
  }
  else if (category === '50-99 games'){
    color = "#dae5e5"
  }
  else if (category === '100-199 games'){
    color = "#ff1a00"
  }
  else if (category === '200+ games'){
    color = "#0b0b0b"
  }
  else {
    const letters = '0123456789ABCDEF';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  }

  return color;
}


  const maxwinChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "%"; // Append '%' to Y-axis labels
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Ensure legends are not displayed
      },
    },
  };

  const roiByRatingChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "%"; // Append '%' to Y-axis labels
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true, // Ensure legends are not displayed
      },
      tooltip: {
        callbacks: {
          // This callback lets you create a custom tooltip label
          label: function (context) {
            // `context` contains a wealth of information about the data point, dataset, etc.
            const percentage = context.raw.toFixed(2); // Access your custom data
            const moreOrLess = context.raw <= 0 ? "less" : "more";
            // Construct the label string
            return `${percentage}% ${moreOrLess} tickets earned than credits spent`;
          },
        },
      },
    },
  };

  const roiChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "%"; // Append '%' to Y-axis labels
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Ensure legends are not displayed
      },
      tooltip: {
        callbacks: {
          // This callback lets you create a custom tooltip label
          label: function (context) {
            // `context` contains a wealth of information about the data point, dataset, etc.
            const percentage = context.raw.toFixed(2); // Access your custom data
            const moreOrLess = context.raw <= 0 ? "less" : "more";
            // Construct the label string
            return `${percentage}% ${moreOrLess} tickets earned than credits spent`;
          },
        },
      },
    },
  };

  const avgWinsChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
      maintainAspectRatio: true,
    },
    plugins: {
      legend: {
        display: true, // Ensure legends are not displayed
      },
    },
  };

  const creditPurchasesChartData = {
    labels: ["Purchases", "Extractions"],
    datasets: [
      {
        label: "Transactions",
        data: [
          creditPurchaseData.purchases / 100,
          -creditPurchaseData.extractions,
        ], // Note the negative sign for extractions
        backgroundColor: [
          "green", // Green color for purchases
          "red", // Red color for extractions
        ],
      },
    ],
  };

  const creditPurchasesChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return Math.abs(value); // Display positive values on both sides
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend if not required
      },
    },
  };

  const playmodeChartData = {
    labels: [ "Pain", "Cakewalk"],
    datasets: [
      {
        label: "Play Mode",
        data: [
          playmodeData.play_mode_counts.pain,
          playmodeData.play_mode_counts.cakewalk,
        ],
        backgroundColor: [
          "rgba(234,160,57,1)",
          "rgba(125,200,207,1)",
        ],
        borderColor: [ "black", "black"],
        borderWidth: 2,
      },
    ],
  };

  const maxwinChartData = {
    labels: [ "Pain", "Cakewalk"],
    datasets: [
      {
        label: "Max Win %",
        data: [
          (playmodeData.max_win_counts.carnage /
            playmodeData.play_mode_counts.carnage) *
            100,
          (playmodeData.max_win_counts.pain /
            playmodeData.play_mode_counts.pain) *
            100,
          (playmodeData.max_win_counts.cakewalk /
            playmodeData.play_mode_counts.cakewalk) *
            100,
        ],
        backgroundColor: [
          "rgba(234,160.57,1)",
          "rgba(125,200, 207, 1",
        ],
        borderColor: ["black", "black"],
        borderWidth: 2,
      },
    ],
  };

  const maxWinsDailyChartData = {
    labels: ["Pain", "Cakewalk"],
    datasets: [
      {
        label: "Rounds won",
        data: [
          playmodeData.avg_wins.pain,
          playmodeData.avg_wins.cakewalk,
        ],
        backgroundColor: [
          "rgba(234,160,57,1)",
          "rgba(125,200, 207, 1",
        ],
        borderColor: [ "black", "black"],
        borderWidth: 2,
      },
    ],
  };

  const avgWinsChartData = {
    labels: [ "Pain", "Cakewalk"],
    datasets: [
      {
        label: "Rounds won",
        data: [
          playmodeData.avg_wins.pain,
          playmodeData.avg_wins.cakewalk,
        ],
        backgroundColor: [
          "rgba(234,160,57,1)",
          "rgba(125,200, 207, 1",
        ],
        borderColor: [ "black", "black"],
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false, // Ensure legends are not displayed
      },
    },
    // other options...
  };

  const avgGamesChartOptions = {
    plugins: {
      legend: {
        display: false, // Ensure legends are not displayed
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        callback: function (value) {
          return value + "%"; // Append '%' to Y-axis labels
        },
      },
    },
  };

  return (
    <div>
      <div className="tabs" style={{ justifyContent: "center" }}>
        <ul className="tabs">
          <li
            className={activeTab === "game-stats" ? "active" : ""}
            style={{
              padding: "10px 15px",
              fontSize: "14px",
              margin: "5px",
            }}
            onClick={() => setActiveTab("game-stats")}
          >
            Game Stats
          </li>
          <li
            className={activeTab === "players-stats" ? "active" : ""}
            style={{
              padding: "10px 15px",
              fontSize: "14px",
              margin: "5px",
            }}
            onClick={() => setActiveTab("players-stats")}
          >
            Players Stats
          </li>
          <li
            className={activeTab === "credits-payouts" ? "active" : ""}
            style={{
              padding: "10px 15px",
              fontSize: "14px",
              margin: "5px",
            }}
            onClick={() => setActiveTab("credits-payouts")}
          >
            Credits and Payouts
          </li>
          <li
            className={activeTab === "reboot-stats" ? "active" : ""}
            style={{
              padding: "10px 15px",
              fontSize: "14px",
              margin: "5px",
            }}
            onClick={() => setActiveTab("reboot-stats")}
          >
            Reboot
          </li>
        </ul>
      </div>
      {activeTab === "credits-payouts" && (
        <div>
          <div className="row">
            <div className="credits-payouts-card">
              <h4
                style={{
                  color: "black",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              >
                Credits and Payouts
              </h4>
              {payoutsData.total_credits_spent > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        {" "}
                        <img
                          src="/credits.webp"
                          alt="Credits"
                          style={{ width: "60px" }}
                        />
                      </th>
                      <th>
                        {" "}
                        <img
                          src="/tickets.webp"
                          alt="Tickets"
                          style={{ width: "60px" }}
                        />
                      </th>
                      <th>
                        {" "}
                        <img
                          src="/extractors.webp"
                          alt="Extractors"
                          style={{ width: "60px" }}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src="/cakewalk.webp"
                          alt="Cakewalk"
                          className="game-mode-image"
                        />
                      </td>
                      <td style={{ fontSize: "18px" }}>
                        {" "}
                        {payoutsData.play_modes.cakewalk.total_credits_spent.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                      </td>{" "}
                      {/* Replace with actual data */}
                      <td
                        style={{
                          color:
                            payoutsData.play_modes.cakewalk
                              .total_credits_spent /
                              100 <
                            payoutsData.play_modes.cakewalk.total_ticket_payouts
                              ? "green"
                              : "red",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {" "}
                        {payoutsData.play_modes.cakewalk.total_ticket_payouts.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}{" "}
                        ({payoutsData.play_modes.cakewalk.roi > 0 ? "+" : "-"}
                        {payoutsData.play_modes.cakewalk.roi.toFixed(1)}%)
                      </td>{" "}
                      {/* Replace with actual data */}
                      <td style={{ fontSize: "18px" }}>
                        {" "}
                        {payoutsData.play_modes.cakewalk.total_extractor_payouts.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                      </td>{" "}
                      {/* Replace with actual data */}
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="/pain.webp"
                          alt="Pain"
                          className="game-mode-image"
                        />
                      </td>
                      <td style={{ fontSize: "18px" }}>
                        {" "}
                        {payoutsData.play_modes.pain.total_credits_spent.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                      </td>{" "}
                      {/* Replace with actual data */}
                      <td
                        style={{
                          color:
                            payoutsData.play_modes.pain.total_credits_spent /
                              100 <
                            payoutsData.play_modes.pain.total_ticket_payouts
                              ? "green"
                              : "red",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {" "}
                        {payoutsData.play_modes.pain.total_ticket_payouts.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}{" "}
                        ( {payoutsData.play_modes.pain.roi > 0 ? "+" : "-"}
                        {payoutsData.play_modes.pain.roi.toFixed(1)}%)
                      </td>{" "}
                      {/* Replace with actual data */}
                      <td style={{ fontSize: "18px" }}>
                        {" "}
                        {payoutsData.play_modes.pain.total_extractor_payouts.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                      </td>{" "}
                      {/* Replace with actual data */}
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="/carnage.webp"
                          alt="Carnage"
                          className="game-mode-image"
                        />
                      </td>
                      <td style={{ fontSize: "18px" }}>
                        {" "}
                        {payoutsData.play_modes.carnage.total_credits_spent.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                      </td>{" "}
                      {/* Replace with actual data */}
                      <td
                        style={{
                          color:
                            payoutsData.play_modes.carnage.total_credits_spent /
                              100 <
                            payoutsData.play_modes.carnage.total_ticket_payouts
                              ? "green"
                              : "red",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {" "}
                        {payoutsData.play_modes.carnage.total_ticket_payouts.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}{" "}
                        ({" "}
                        {(payoutsData.play_modes.carnage.total_ticket_payouts /
                          (payoutsData.play_modes.carnage.total_credits_spent /
                            100) -
                          1) *
                          100 >
                        0
                          ? "+"
                          : "-"}
                        {payoutsData.play_modes.carnage.roi.toFixed(1)}%)
                      </td>{" "}
                      {/* Replace with actual data */}
                      <td style={{ fontSize: "18px" }}>
                        {" "}
                        {payoutsData.play_modes.carnage.total_extractor_payouts.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                      </td>{" "}
                      {/* Replace with actual data */}
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        {payoutsData.total_credits_spent.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                      </td>{" "}
                      {/* Totals of Credits Spent */}
                      <td
                        style={{
                          color:
                            payoutsData.total_credits_spent / 100 <
                            payoutsData.total_ticket_payouts
                              ? "green"
                              : "red",
                          fontWeight: "bold",
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        {payoutsData.total_ticket_payouts.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}{" "}
                        (
                        {(payoutsData.total_ticket_payouts /
                          (payoutsData.total_credits_spent / 100) -
                          1) *
                          100 >
                        0
                          ? "+"
                          : "-"}
                        {(
                          (payoutsData.total_ticket_payouts /
                            (payoutsData.total_credits_spent / 100) -
                            1) *
                          100
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })}
                        %)
                      </td>{" "}
                      {/* Totals of Ticket Payouts */}
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        {payoutsData.total_extractor_payouts.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 0 }
                        )}
                      </td>{" "}
                      {/* Totals of Extractor Payouts */}
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <div className="chart-container">
              <h4 style={{ color: "black" }}>
                Unofficial and Probably Inaccurate Inflows/Outflows
              </h4>
              <Line data={creditPurchaseChartData} options={chartOptions} />
            </div>
          </div>
          <div className="row">
            <div className="chart-container">
              <h4 style={{ color: "black" }}>
                Tickets Earned vs Credits Spent by Play Mode
              </h4>
              <Line data={dailyRoiChartData} options={roiChartOptions} />
            </div>
            <div className="chart-container">
              <h4 style={{ color: "black" }}>
                Tickets Earned vs Credits Spent by Rating
              </h4>
              <Line
                data={dailyRoiByRatingChartData}
                options={roiByRatingChartOptions}
              />
            </div>
          </div>
          <div className="row">
            <div className="chart-container">
              <h4 style={{ color: "black" }}>Total $gg in - Total $gg out</h4>
              <div
                style={{
                  color:
                    creditPurchaseData.purchases -
                      creditPurchaseData.extractions >
                    0
                      ? "green"
                      : "red",
                  fontWeight: "bold",
                }}
              >
                {Number(creditPurchaseData.purchases) -
                Number(creditPurchaseData.extractions)
                  ? (
                      Number(creditPurchaseData.purchases) -
                      Number(creditPurchaseData.extractions)
                    ).toLocaleString()
                  : "--"}
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === "reboot-stats" && (
        <>
          <div className="row">
            <div className="chart-container">
              <h4 style={{ color: "black" }}>
                GG Claims (may not be everything)
              </h4>
              <Line data={ggClaimsChartData} options={chartOptions} />
            </div>
          </div>
        </>
      )}
      {activeTab === "players-stats" && (
        <>
          <div className="row">
            <div className="chart-container">
              <h4 style={{ color: "black" }}>Daily Active Users</h4>
              <Line data={playerCountData} options={chartOptions} />
            </div>
            <div className="chart-container">
              <h4 style={{ color: "black" }}>New Players</h4>
              <Line data={newPlayerCountData} options={chartOptions} />
            </div>
          </div>
          <div className="row">
            <div className="chart-container">
              <h4 style={{ color: "black" }}>
                Average and Median Games Played
              </h4>
              <Line data={avgGamesChartData} options={avgWinsChartOptions} />
            </div>
            <div className="credits-payouts-card">
              <h4
                style={{
                  color: "black",
                  marginBottom: "20px",
                }}
              >
                Games Played per Wallet
              </h4>
              <table>
                <thead>
                  <tr>
                    <th style={{ paddingLeft: "20px", fontStyle: "italic" }}>
                      Games Played
                    </th>
                    <th style={{ fontStyle: "italic" }}>Wallet Counts</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ paddingLeft: "20px" }}>
                    <td style={{ paddingLeft: "20px" }}>15 or more (olds) </td>
                    <td>
                      <b>{walletGamesCounts.olds}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: "20px" }}>14 or less (newbs) </td>
                    <td>
                      <b>{walletGamesCounts.newbs}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="chart-container">
              <h4 style={{ color: "black" }}>Average Player Glicko Rating</h4>
              <Line data={avgGlickoChartData} options={avgWinsChartOptions} />
            </div>
            <div className="chart-container">
              <h4 style={{ color: "black" }}>
                Average Player Volatility Rating
              </h4>
              <Line
                data={avgVolatilityChartData}
                options={avgWinsChartOptions}
              />
            </div>
          </div>
          <div className="row">
            <div className="chart-container">
            <h4 style={{ color: "black" }}>
                Players in Each Rating
              </h4>
              <Line
                data={dailyRatingsCountChartData}
                options={chartOptions}
              />
            </div>
            <div className="chart-container">
            <h4 style={{ color: "black" }}>
                Retention (7 games in the last 7 days)
              </h4>
            <Bar data={retentionChartData} options={retentionChartOptions} />;
            </div>
          </div>
        </>
      )}
      {activeTab === "game-stats" && (
        <>
          <div className="row">
            <div className="chart-container">
              <h4 style={{ color: "black" }}>Games Played</h4>
              <Bar data={playmodeChartData} options={chartOptions} />
            </div>
            <div className="chart-container">
              <h4 style={{ color: "black" }}>
                Daily Sessions and Forfeits/Abandoned
              </h4>
              <Line data={combinedChartData} options={chartOptions} />
            </div>
          </div>
          <div className="row">
            <div className="chart-container">
              <h4 style={{ color: "black" }}>Avg wins per day</h4>
              <Line data={combinedAvgWinsData} options={chartOptions} />
            </div>
            <div className="chart-container">
            <h4 style={{ color: "black" }}>Average Wins by Rating</h4>
              <Line data={dailyWinsByRatingChartData} options={avgWinsChartOptions} />
            </div>
          </div>
          <div className="row">
          <div className="chart-container">
              <h4 style={{ color: "black" }}>Max Wins % per day</h4>
              <Line data={combinedMaxWinsData} options={maxwinChartOptions} />
            </div>
            <div className="chart-container">
            <h4 style={{ color: "black" }}>Max Wins % by Rating</h4>
              <Line data={dailyMaxWinsByRatingChartData} options={maxwinChartOptions} />
            </div>
            </div>
            <div className="row">
          <div className="chart-container">
              <h4 style={{ color: "black" }}>
                Average Wins in Players First 3 Games
              </h4>
              <Line data={avgFirstWinsChartData}  />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default StatsPage;
