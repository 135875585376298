import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Leaderboard() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [playerData, setPlayerData] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [searchIndex, setSearchIndex] = useState(null); // State to store the index
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchedPlayerData, setSearchedPlayerData] = useState(null);
  const [sortField, setSortField] = useState("ratings"); // Default to sorting by ratings
  const [sortDirection, setSortDirection] = useState("desc"); // or 'asc' for ascending

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/player`;
    navigate(path, {
      state: {
        wallet_address: searchResult.wallet,
        ens_name: searchResult.ens_name,
      },
    });
  };

  const sortTableData = (field, direction) => {
    const sortedData = [...tableData].sort((a, b) => {
      if (field === "ratings" || field === "roi" || field === "volatility") {
        const valueA = Number(a[field]);
        const valueB = Number(b[field]);
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      }
      // Add more sorting conditions if needed
      return 0;
    });
    setTableData(sortedData);
  };

  const rowButtonClick = (row) => {
    let path = `/player`;
    navigate(path, {
      state: {
        wallet_address: row.wallet,
        ens_name: row.ens_name,
        rating_level: row.rating_level,
        streamer_link: row.streamer_link,
      },
    });
  };

  function getDate(x) {
    const myDate = new Date(x * 1000);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    // You can optionally specify the locale to ensure consistent formatting
    return myDate.toLocaleDateString('en-US', options);
  }

  const handleRefresh = () => {
    fetchData(true); // Force data refresh
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setSearchResult(null);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    handleSearchClick(); // Call the search function
  };

  const handleSearchClick = async () => {
    setSearchLoading(true); // Start loading
    try {
      // Update the URL to match your API's search endpoint and query parameter
      const response = await fetch(`https://api.ragequitchecker.com/leaderboard/search?query=${encodeURIComponent(searchTerm)}`);
      if (!response.ok) throw new Error('Failed to fetch data');
      const jsonData = await response.json();
  
      // Assuming the API returns an array of results
      setSearchedPlayerData(jsonData); // Update the table with the search results
      setSearchResult(jsonData); // Reset the individual search result if used elsewhere
      setSearchIndex(jsonData.rank); // Reset the search index if used elsewhere
    } catch (error) {
      console.error("Error fetching data:", error);
      setPlayerData({}); // Clear the table data on error
    } finally {
      setSearchLoading(false); // End loading
    }
  };
  

  const handleRowClick = async (index, wallet) => {
    setSelectedRowIndex(selectedRowIndex === index ? null : index);
  };

  useEffect(() => {
    const cache = localStorage.getItem("leaderboardData");
    const lastFetch = localStorage.getItem("lastFetchTime");

    const isDataOld =
      Date.now() - new Date(lastFetch || 0).getTime() > 0.1 * 60 * 60 * 1000; // 1 hours

    if (cache && !isDataOld) {
      setTableData(JSON.parse(cache));
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    console.log("Fetching data");
    try {
      const response = await fetch(
        `https://api.ragequitchecker.com/leaderboard`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      jsonData.sort((a, b) => b.block_num - a.block_num);
      setTableData(jsonData);
      localStorage.setItem("leaderboardData", JSON.stringify(jsonData));
      localStorage.setItem("lastFetchTime", new Date().toISOString());
    } catch (error) {
      console.error("Error fetching data:", error);
      setTableData(null);
    } finally {
      setLoading(false);
    }
  };

  const renderRow = (item, index) => (
    <>
      <tr key={index} onClick={() => handleRowClick(item.wallet)}>
        <td>{index === 0 ? "👑" : index + 1}</td>
        <td>{item.ens_name || item.wallet}</td>
        <td>
          {item.ratings !== "error" ? Number(item.ratings).toFixed(3) : "error"}{" "}
        </td>
        <td>{renderImage(item.rating_level)}</td>
        <td
          style={{
            color: item.roi >= 0 ? "green" : "red",
          }}
        >
          {item.roi >= 0 ? "+" : ""}
          {item.roi ? Number(item.roi).toFixed(1) : 0}%
        </td>
      </tr>
      <tr key={`detail-${index}`}>
        <td colSpan="100%" style={{ backgroundColor: "grey" }}>
          {" "}
          {/* Adjust the colspan as per your table's column count */}
          {/* Render additional information here */}
          <div>
            Last game:{" "}
            {item.last_session
              ? getDate(item.last_session.timestamp)
              : "Unknown"}
          </div>
          <div>
            Session id:{" "}
            {item.last_session ? item.last_session.session_id : "Unknown"}
          </div>
          <button style={{ backgroundColor: "black" }} onClick={routeChange}>
            View Player
          </button>
        </td>
      </tr>
    </>
  );

  const renderImage = (ratingLevel) => {
    const imageUrl = `/${ratingLevel}.webp`; // Adjust path as needed
    return (
      <img
        src={imageUrl}
        alt={ratingLevel}
        title={ratingLevel}
        style={{ width: "75px", height: "auto" }}
      />
    );
  };

  return (
    <main>
      {loading && (
        <img src="/battlePlanText.webp" alt="Loading..." className="spinner" />
      )}
      <form onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Search address..."
          value={searchTerm}
          onChange={handleInputChange}
        />
        <button type="Submit">Search</button>
      </form>
      {searchLoading && (
        <img src="/battlePlanText.webp" alt="Loading..." className="spinner" />
      )}
      {!searchLoading && searchResult && (
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Who</th>
              <th>Rating</th>
              <th></th>
              <th>ROI</th>
            </tr>
          </thead>
          <tbody>{renderRow(searchedPlayerData, searchIndex)}</tbody>
        </table>
      )}
      {!loading && tableData.length > 0 && (
              <>
        <div className="table-container">
          <table style={{paddingTop:"0px"}}>
            <thead>
              <tr>
                <th style={{ backgroundColor: "#4CAF50" }}>Rank</th>
                <th style={{ backgroundColor: "#4CAF50" }}>Who</th>
                <th
                  style={{ backgroundColor: "#4CAF50" }}
                  title="This is a glicko2 rating used by the game for matchmaking."
                  onClick={() => {
                    const newDirection =
                      sortField === "ratings" && sortDirection === "asc"
                        ? "desc"
                        : "asc";
                    setSortField("ratings");
                    setSortDirection(newDirection);
                    sortTableData("ratings", newDirection);
                  }}
                >
                  Rating
                </th>
                <th style={{ backgroundColor: "#4CAF50" }}></th>
                <th
                  title="Defined as tickets earned compared to credits spent"
                  onClick={() => {
                    const newDirection =
                      sortField === "roi" && sortDirection === "asc"
                        ? "desc"
                        : "asc";
                    setSortField("roi");
                    setSortDirection(newDirection);
                    sortTableData("roi", newDirection);
                  }}
                  style={{ backgroundColor: "#4CAF50" }}
                >
                  ROI
                </th>
                <th style={{ backgroundColor: "#4CAF50" }}>Last Session</th>
                <th                   title="Player glicko volatility rating. It should be close to zero or something's up"
                  onClick={() => {
                    const newDirection =
                      sortField === "volatility" && sortDirection === "asc"
                        ? "desc"
                        : "asc";
                    setSortField("volatility");
                    setSortDirection(newDirection);
                    sortTableData("volatility", newDirection);
                  }}
                  style={{ backgroundColor: "#4CAF50" }}>Volatility</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <>
                  <tr
                    key={index}
                    onClick={() => handleRowClick(index, row.wallet)}
                  >
                    <td>{index === 0 ? "👑" : index + 1}</td>
                    <td>{row.ens_name || row.wallet}</td>
                    <td>
                      {row.ratings !== "error"
                        ? Number(row.ratings).toFixed(3)
                        : "error"}{" "}
                    </td>
                    <td>{renderImage(row.rating_level)}</td>
                    <td
                      style={{
                        color: row.roi >= 0 ? "green" : "red",
                      }}
                    >
                      {row.roi >= 0 ? "+" : ""}
                      {row.roi ? Number(row.roi).toFixed(1) : 0}%
                    </td>
                    <td>
                      {" "}
                      {row.last_session
                        ? getDate(row.last_session.timestamp)
                        : "Unknown"}
                    </td>
                    <td>{row.volatility ? Number(row.volatility).toFixed(2) : "--"}</td>
                  </tr>
                  {selectedRowIndex === index && (
                    <tr key={`detail-${index}`}>
                      <td colSpan="100%" style={{ backgroundColor: "grey" }}>
                        {" "}
                        {/* Adjust the colspan as per your table's column count */}
                        {/* Render additional information here */}
                        <div>
                          Last game:{" "}
                          {row.last_session
                            ? getDate(row.last_session.timestamp)
                            : "Unknown"}
                        </div>
                        <div>
                          Session id:{" "}
                          {row.last_session
                            ? row.last_session.session_id
                            : "Unknown"}
                        </div>
                        <button
                          style={{ backgroundColor: "black" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            rowButtonClick(row);
                          }}
                        >
                          View Player
                        </button>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
        </>
      )}
    </main>
  );
}

export default Leaderboard;
