import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { JsonRpcProvider } from "ethers";
import PlayerRoiTable from "./PlayerRoi";
import GlickoChart from "./GlickoChart";
import PlayerSessionsTable from "./RecentPlayerSessions";
import PlayerForfeitsTable from "./PlayerForfeitsTable";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function PlayerProfile() {

  const BP_RATINGS = [
    { "min": -10, "max": -4, "name": 'Casual I' },
    { "min": -4, "max": -2.75, "name": 'Casual II' },
    { "min": -2.75, "max": -1.7, "name": 'Casual III' },
    { "min": -1.7, "max": -1.25, "name": 'Bruiser I' },
    { "min": -1.25, "max": -0.75, "name": 'Bruiser II' },
    { "min": -0.75, "max": -0.38, "name": 'Bruiser III' },
    { "min": -0.38, "max": -0.05, "name": 'Ace I' },
    { "min": -0.05, "max": 0.2, "name": 'Ace II' },
    { "min": 0.2, "max": 0.44, "name": 'Ace III' },
    { "min": 0.44, "max": 0.65, "name": 'Titan I' },
    { "min": 0.65, "max": 0.83, "name": 'Titan II' },
    { "min": 0.83, "max": 10, "name": 'Titan III' }
  ];
  const location = useLocation();
  const playerWallet = location.state.wallet_address;
  const ens_name = location.state.ens_name;
  const [isLoading, setIsLoading] = useState(false);
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const [playerData, setPlayerData] = useState({
    counts: { cakewalk: 0, pain: 0, carnage: 0 },
    ratings: [0],
    roi: [],
    opponent_ratings: [],
    recent_sessions: [],
    forfeits: []
  });
  const [activeTab, setActiveTab] = useState("glickoTracker");

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [avatarUrl, setAvatarUrl] = useState("");
  const modeImages = {
    cakewalk: "/cakewalk.webp", // Replace with actual image paths
    pain: "/pain.webp",
    carnage: "/carnage.webp",
  };

  const playModeColors = {
    cakewalk: "rgba(125,200, 207, 1)",
    pain: "rgba(234,160.57,1)",
    carnage: "rgba(220,64,55,1)",
    // Add more modes and their colors as needed
  };

  // Example calculation within the component, before the return statement
  const highestRating = Math.max(
    ...playerData.ratings.slice(4).map((rating) => rating[1])
  ); // Replace `rating.value` with the correct path to the rating in your data structure
  const lowestRating = Math.min(
    ...playerData.ratings.slice(4).map((rating) => rating[1])
  ); // Same as above

  const currentRating = Number(playerData.ratings.slice(-1).map((rating) => rating[1])).toFixed(2);
  const matchingBucket = BP_RATINGS.find(bucket => currentRating >= bucket.min && currentRating <= bucket.max);

  useEffect(() => {
    console.log("useEffect()");
    const fetchAvatar = async () => {
      setIsAvatarLoading(true);
      const avatar = await fetchEnsAvatar(playerWallet);
      setAvatarUrl(avatar);
      setIsAvatarLoading(false);
    };

    const fetchChartData = async () => {
      setIsLoading(true);
      try {
        if (playerWallet) {
          console.log("just before fetch");
        }
        const response = await fetch(
          `https://api.ragequitchecker.com/stats?wallet_address=${playerWallet}`
        );
        const data = await response.json();
        console.log("API Response:", data);
        const sortedRatings = data.ratings.sort((a, b) => a[0] - b[0]);
        setPlayerData({ ...data, ratings: sortedRatings }); // Assuming the API returns data in [[date, float]] format
      } catch (error) {
        console.error("Error fetching chart data:", error);
        // Handle the error appropriately
      } finally {
        setIsLoading(false);
        console.log("recent_sessions", playerData.recent_sessions);
      }
    };

    if (playerWallet) {
      fetchChartData();
      fetchAvatar(); // Fetch the ENS avatar
    }
  }, [playerWallet]);

  const renderImage = (currentRating) => {
    let imageUrl = "";
    if (matchingBucket) {
      const imageName = matchingBucket.name;
      imageUrl = `${imageName}.webp`;
    }
    return (
      <img
        src={imageUrl}
        alt={currentRating}
        style={{ width: "60px", height: "auto" }}
        class="overlay-image"
      />
    );
  };

  const dataWithLabels = playerData.ratings.slice(4).map((pair) => {
    return {
      x: pair[1], // Assuming this is your x value like session_id or time
      y: pair[0], // Your Glicko rating
      playMode: pair[2],
      victories: pair[3], // Additional info you want in the tooltip
      // Add any other data you want to show in the tooltip
    };
  });

  const playerRoiChartData = {
    labels: playerData.roi.map((item) => item.played_date), // Assuming both datasets cover the same date range
    datasets: [
      {
        label: "Cakewalk",
        data: playerData.roi.map((item) => item.cakewalk),
        borderColor: "rgba(125,200, 207, 1)", // Color for the Daily Sessions line
        backgroundColor: "rgba(125,200, 207, 0.5)", // Light green with transparency
        tension: 0.25, // Line smoothness
      },
      {
        label: "Pain",
        data: playerData.roi.map((item) => item.pain),
        borderColor: "rgba(234,160,57,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(234,160,57,0.5)", // Light red with transparency
        tension: 0.25, // Line smoothness
      },
      {
        label: "Carnage",
        data: playerData.roi.map((item) => item.carnage),
        borderColor: "rgba(220,64,55,1)", // Color for the Forfeit Sessions line
        backgroundColor: "rgba(220,64,55,0.5)", // Light red with transparency
        tension: 0.25, // Line smoothness
      },
    ],
  };

  const playerRoiChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "%"; // Append '%' to Y-axis labels
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Ensure legends are not displayed
      },
      tooltip: {
        callbacks: {
          // This callback lets you create a custom tooltip label
          label: function (context) {
            // `context` contains a wealth of information about the data point, dataset, etc.
            const percentage = context.raw.toFixed(2); // Access your custom data
            const moreOrLess = context.raw <= 0 ? "less" : "more";
            // Construct the label string
            return `${percentage}% ${moreOrLess} tickets earned than credits spent`;
          },
        },
      },
    },
  };

  const chartData = {
    labels: playerData.ratings.slice(4).map((pair) => pair[1]), // Assuming this correctly represents your x-axis labels (e.g., session IDs)
    datasets: [
      {
        label: "Glicko2 Ratings after first 5 games",
        // Assuming pair[0] is glicko rating and pair[2] is play_mode
        data: dataWithLabels,
        borderColor: "black", // Line color
        // Specify colors for each point based on play mode
        pointBackgroundColor: playerData.ratings
          .slice(4)
          .map((pair) => playModeColors[pair[2]] || "#CCCCCC"), // Default to light grey
        borderWidth: 0.5,
        pointRadius: 3,
        pointHoverRadius: 7,
        tension: 0.1,
        showLine: true,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true, // Ensure legends are not displayed
      },
      tooltip: {
        callbacks: {
          // This callback lets you create a custom tooltip label
          label: function (context) {
            // `context` contains a wealth of information about the data point, dataset, etc.
            const playMode = context.raw.playMode; // Access your custom data
            const rating = context.formattedValue;
            const victories = context.raw.victories; // The y-value of the data point
            // Construct the label string
            return `${playMode}: ${rating} - Rounds won: ${victories}`;
          },
        },
      },
    },
    // Include other options as needed
  };

  async function fetchEnsAvatar(ethAddress) {
    const provider = new JsonRpcProvider(
      "https://eth-mainnet.g.alchemy.com/v2/Rz4T2iR6462VKlTxTCBAI1P-YawtAQkr"
    );
    let ensName = await provider.lookupAddress(ethAddress);
    if (ensName) {
      let avatar = await provider.getAvatar(ensName);
      if (avatar) {
        console.log(`Avatar URL for ${ensName}: ${avatar}`);
        return avatar;
      }
    }
    // Generate a light grey square image of 100x100 pixels
    return "https://via.placeholder.com/100x100.png?text=+&bg=cccccc"; // Return URL to the generated placeholder image
  }

  function formatWalletAddress(walletAddress) {
    if (!walletAddress) return "";
    const firstPart = walletAddress.substring(0, 6); // Get first 5 characters
    const lastPart = walletAddress.substring(walletAddress.length - 6); // Get last 4 characters
    return `${firstPart}...${lastPart}`; // Combine with ellipses
  }

  return (
    <main className="profile-and-chart-container">
      <div className="player-card">
        <div className="profile-section">
        {isAvatarLoading && (
            <img
              src="/battlePlanText.webp"
              alt="Loading..."
              className="spinner"
              style={{width:"60px", height:"60px"}}
            />
          )}
          {!isAvatarLoading && avatarUrl && (
            <div class="image-container">
            <img src={avatarUrl} alt="ENS Avatar" className="ens-avatar" class="background-image"/>
            {!isLoading ? renderImage(currentRating) : ''}
          </div>
          )}
          <div className="name-wallet-info">
            {ens_name && <h3>{ens_name}</h3>}
            <p>{formatWalletAddress(playerWallet)}</p>
          </div>
          {isLoading && (
            <img
              src="/battlePlanText.webp"
              alt="Loading..."
              className="spinner"
            />
          )}
          {!isLoading && (
            <div
              className="ratings-info"
              style={{ backgroundColor: "lightgrey" }}
            >
              <h4>Glicko2 Ratings</h4>
              <p>Highest: {highestRating.toFixed(2)}</p>
              <p>Lowest: {lowestRating.toFixed(2)}</p>
            </div>
          )}
        </div>
        <div>
          {!isLoading && (
            <div
              className="ratings-info"
              style={{ backgroundColor: "lightgrey" }}
            >
              <h4 style={{ textAlign: "center" }}>Games Played</h4>
              <ul className="game-mode-list">
                <li className="game-mode-item">
                  <img
                    src={modeImages["cakewalk"]}
                    alt={"Cakewalk"}
                    className="game-mode-image"
                  />
                  <span className="game-mode-count">{`${playerData.counts["cakewalk"]}`}</span>
                </li>
                <li className="game-mode-item">
                  <img
                    src={modeImages["pain"]}
                    alt={"Pain"}
                    className="game-mode-image"
                  />
                  <span className="game-mode-count">{`${playerData.counts["pain"]}`}</span>
                </li>
                <li className="game-mode-item">
                  <img
                    src={modeImages["carnage"]}
                    alt={"Carnage"}
                    className="game-mode-image"
                  />
                  <span className="game-mode-count">{`${playerData.counts["carnage"]}`}</span>
                </li>
              </ul>
              <h4>Games Forfeited/Abandoned</h4>
              <p>
                {playerData.forfeits} (
                {(
                  (playerData.forfeits /
                    (playerData.counts["carnage"] +
                      playerData.counts["pain"] +
                      playerData.counts["cakewalk"])) *
                  100
                ).toFixed(1)}
                %)
              </p>
            </div>
          )}
          {!isLoading && playerData.total_entry_fees > 0 && (
            <div
              className="ratings-info"
              style={{ backgroundColor: "lightgrey" }}
            >
              <h4>Credits Spent</h4>
              <p>{playerData.total_entry_fees.toLocaleString()}</p>
              <h4>Tickets Earned</h4>
              <p
                style={{
                  color:
                    playerData.total_entry_fees / 100 <
                    playerData.total_tickets_earned
                      ? "green"
                      : "red",
                  fontWeight: "bold",
                }}
              >
                {playerData.total_tickets_earned.toLocaleString()} (
                {(playerData.total_tickets_earned /
                  (playerData.total_entry_fees / 100) -
                  1) *
                  100 >
                0
                  ? "+"
                  : "-"}
                {(
                  (playerData.total_tickets_earned /
                    (playerData.total_entry_fees / 100) -
                    1) *
                  100
                ).toFixed(2)}
                %)
              </p>
              <h4>Extractors Earned</h4>
              <p>{playerData.total_extractors_earned.toLocaleString()}</p>
            </div>
          )}
        </div>
      </div>
      <div className="chart-and-table-container">
        <div className="player-chart-container">
          {/* Tab navigation */}
          <ul className="tabs">
            <li
              className={activeTab === "glickoTracker" ? "active" : ""}
              onClick={() => handleTabClick("glickoTracker")}
            >
              Glicko Tracker
            </li>
            <li
              className={activeTab === "payoutTable" ? "active" : ""}
              onClick={() => handleTabClick("payoutTable")}
            >
              Credits and Payouts
            </li>
            <li
              className={activeTab === "recentSessions" ? "active" : ""}
              onClick={() => handleTabClick("recentSessions")}
            >
              Recent Sessions
            </li>
            <li
              className={activeTab === "forfeitSessions" ? "active" : ""}
              onClick={() => handleTabClick("forfeitSessions")}
            >
              Forfeited Sessions
            </li>
          </ul>
          {isLoading && (
            <img
              src="/battlePlanText.webp"
              alt="Loading..."
              className="spinner"
            />
          )}
          {/* Tab content */}
          <div className="tab-content">
            {!isLoading && activeTab === "glickoTracker" && (
              <GlickoChart playerData={playerData} />
            )}
            {!isLoading && activeTab === "payoutTable" && (
              <div>
                <PlayerRoiTable payouts={playerData} />
                <h4 style={{ color: "black" }}>
                  Tickets Earned vs Credits Spent
                </h4>
                <Line
                  data={playerRoiChartData}
                  options={playerRoiChartOptions}
                />
              </div>
            )}
            {!isLoading && activeTab === "recentSessions" && (
                <PlayerSessionsTable sessions={playerData} />
            )}
            {!isLoading && activeTab === "forfeitSessions" && (
                <PlayerForfeitsTable sessions={playerData} />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default PlayerProfile;
