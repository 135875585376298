import React from 'react';

function Header() {
    return (
        <header>
            <h1>Battle Planner!</h1>
        </header>
    );
}

export default Header;
