import React, { useState, useEffect } from "react";

function ActiveSessions() {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(60); // Refresh every 60 seconds
  const [autoRefresh, setAutoRefresh] = useState(false);

  // Function to fetch active sessions
  const fetchSessions = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://api.ragequitchecker.com/activesessions"
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      const sortedData = data.sort((a, b) => b.session_id - a.session_id);
      
      setSessions(sortedData);
      setError(null); // Reset error state on successful fetch
    } catch (err) {
      setError(err.message); // Set error state on failure
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // useEffect to run on component mount and set an interval for refreshing data
  useEffect(() => {
    fetchSessions();
    let intervalId;
    if (autoRefresh) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            fetchSessions(); // Refresh data
            return 60; // Reset countdown
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [autoRefresh]);

  // Render component
  return (
    <main>
      {loading && (
        <img src="/battlePlanText.webp" alt="Loading..." className="spinner" />
      )}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <div>
          <b style={{ color: "black", paddingTop: "0px" }}>
            Total active sessions: {sessions.length}
          </b >
          <br></br>
          <b style={{ color: "red", paddingTop: "0px" }}>
            Total sessions in initiation phase: {sessions.filter(session => session.wallet_info.total_sessions < 15).length}
          </b>
          <br></br>
          <button
            style={{
              backgroundColor: "black",
              padding: "5px 10px",
              fontSize: "12px",
              margin: "5px",
            }}
            onClick={() => setAutoRefresh(!autoRefresh)}
          >
            {autoRefresh ? "Disable" : "Enable"} Auto-Refresh
          </button>
          {autoRefresh && (
            <div
              className="timer"
              style={{
                color: "black",
                fontSize: "12px",
                justifyContent: "flex-end",
              }}
            >
              Refreshing in: {countdown} seconds
            </div>
          )}
          <table>
            <thead>
              <tr>
                <th style={{ backgroundColor: "green" }}>Session Id</th>
                <th style={{ backgroundColor: "green" }}>Wallet</th>
                <th style={{ backgroundColor: "green" }}>Playmode</th>
                <th style={{ backgroundColor: "green" }}>Sessions Played</th>
                <th style={{ backgroundColor: "green" }}>Current Glicko</th>
                <th style={{ backgroundColor: "green" }}>Current Volatility</th>
                {/* Add more headers as needed */}
              </tr>
            </thead>
            <tbody>
              {sessions.map((session) => (
                <tr key={session.session_id}>
                  <td style={{ color: session.wallet_info.total_sessions < 15 ? 'red' : 'black' }}>{session.session_id}</td>
                  <td style={{ color: session.wallet_info.total_sessions < 15 ? 'red' : 'black' }}>
                    {session.wallet_info.ens === null
                      ? session.wallet_info.address
                      : session.wallet_info.ens}
                  </td>
                  <td style={{ color: session.wallet_info.total_sessions < 15 ? 'red' : 'black' }}>
                    {session.play_mode === null ? "--" : session.play_mode}
                  </td>
                  <td style={{ color: session.wallet_info.total_sessions < 15 ? 'red' : 'black' }}>{session.wallet_info.total_sessions}</td>
                  <td style={{ color: session.wallet_info.total_sessions < 15 ? 'red' : 'black' }}>{session.wallet_info.rating === null ? 0 : parseFloat(session.wallet_info.rating).toFixed(2)}</td>
                  <td style={{ color: session.wallet_info.total_sessions < 15 ? 'red' : 'black' }}>{session.wallet_info.volatility === null ? 0 : parseFloat(session.wallet_info.volatility).toFixed(2)}</td>
                  {/* Add more columns as needed */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </main>
  );
}

export default ActiveSessions;
