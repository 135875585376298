import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";

function GlickoChart(playerData) {

  const BP_RATINGS = [
    { "min": -10, "max": -4, "name": 'Casual I' },
    { "min": -4, "max": -2.75, "name": 'Casual II' },
    { "min": -2.75, "max": -1.7, "name": 'Casual III' },
    { "min": -1.7, "max": -1.25, "name": 'Bruiser I' },
    { "min": -1.25, "max": -0.75, "name": 'Bruiser II' },
    { "min": -0.75, "max": -0.38, "name": 'Bruiser III' },
    { "min": -0.38, "max": -0.05, "name": 'Ace I' },
    { "min": -0.05, "max": 0.2, "name": 'Ace II' },
    { "min": 0.2, "max": 0.44, "name": 'Ace III' },
    { "min": 0.44, "max": 0.65, "name": 'Titan I' },
    { "min": 0.65, "max": 0.83, "name": 'Titan II' },
    { "min": 0.83, "max": 10, "name": 'Titan III' }
  ];
  
    console.log(playerData);
    const data = playerData.playerData;
    console.log(data);
    const playModeColors = {
        cakewalk: "rgba(125,200, 207, 1)",
        pain: "rgba(234,160.57,1)",
        carnage: "rgba(220,64,55,1)",
        // Add more modes and their colors as needed
      };

    const dataWithLabels = data.ratings.map((pair) => {
        return {
          x: pair[0], // Assuming this is your x value like session_id or time
          y: pair[1], // Your Glicko rating
          playMode: pair[2],
          victories: pair[3],
          volatility: pair[4] // Additional info you want in the tooltip
          // Add any other data you want to show in the tooltip
        };
      });

    const chartData = {
        labels: data.ratings.map((pair) => pair[0]), // Assuming this correctly represents your x-axis labels (e.g., session IDs)
        datasets: [
          {
            label: "Glicko2 Ratings",
            // Assuming pair[0] is glicko rating and pair[2] is play_mode
            data: dataWithLabels,
            borderColor: "black", // Line color
            // Specify colors for each point based on play mode
            pointBackgroundColor: data.ratings
              .map((pair) => playModeColors[pair[2]] || "#CCCCCC"), // Default to light grey
            borderWidth: 0.5,
            pointRadius: 3,
            pointHoverRadius: 7,
            tension: 0.1,
            showLine: true,
          },
          {
            label: "Opponent Glicko2 Ratings",
            // Assuming pair[0] is glicko rating and pair[2] is play_mode
            data: data.ratings.map((item) => item[5]),
            borderColor: "black", // Line color
            // Specify colors for each point based on play mode
            pointBackgroundColor: "grey", // Default to light grey
            borderWidth: 0.5,
            pointRadius: 3,
            pointHoverRadius: 7,
            tension: 0.1,
            showLine: true,
            tooltip: {
              callbacks: {
                // This callback lets you create a custom tooltip label
                label: function (context) {
                  // `context` contains a wealth of information about the data point, dataset, etc.
                  const glicko = context.raw; // Access your custom data // The y-value of the data point
                  // Construct the label string
                  let labelString = "";
                  if(glicko !== undefined) {
                      labelString = `Opponent Average Glicko: ${glicko}`
                  }
                  else {
                      labelString = `${glicko}`
                  }
                  return labelString;
                },
              },
            },
          },
          {
            label: "Volatility Ratings",
            // Assuming pair[0] is glicko rating and pair[2] is play_mode
            data: data.ratings.map((item) => item[4]),
            borderColor: "black", // Line color
            pointBackgroundColor: "black", // Default to light grey
            borderWidth: 0.25,
            pointRadius: 1.5,
            pointHoverRadius: 7,
            tension: 0.1,
            showLine: true,
            tooltip: {
              callbacks: {
                // This callback lets you create a custom tooltip label
                label: function (context) {
                  // `context` contains a wealth of information about the data point, dataset, etc.
                  const volatility = context.raw; // Access your custom data // The y-value of the data point
                  // Construct the label string
                  let labelString = "";
                  if(volatility !== undefined) {
                      labelString = `Volatility: ${volatility}`
                  }
                  else {
                      labelString = `${volatility}`
                  }
                  return labelString;
                },
              },
            },
          },
        ],
      };

    const chartOptions = {
        plugins: {
          legend: {
            display: true, // Ensure legends are not displayed
          },
          title: {      
            display: true, // Enable the title
            text: 'Last 200 games',
            font: {
              size: 16 // Font size of the title
            },
            color: 'black',
          },
          tooltip: {
            callbacks: {
              // This callback lets you create a custom tooltip label
              label: function (context) {
                // `context` contains a wealth of information about the data point, dataset, etc.
                const playMode = context.raw.playMode; // Access your custom data
                const rating = context.formattedValue;
                const victories = context.raw.victories; // The y-value of the data point
                // Construct the label string
                let labelString = "";
                if(playMode !== undefined) {
                    labelString = `Play mode: ${playMode} | Glicko: ${rating} | Rounds won: ${victories}`
                }
                else {
                    labelString = `Glicko: ${rating}`
                }
                return labelString;
              },
            },
          },
        }
        // Include other options as needed
      };
    
    return (
        <Line data={chartData} options={chartOptions} />
    );
}

export default GlickoChart;